import React, { PropTypes } from 'react'
import css from './SearchMediaItem.scss'
import moment from 'moment-timezone';

export default class SearchMediaItem extends React.Component {
  constructor(props, _railsContext) {
    super(props)
  }

  handleRepost(e) {
    this.props.onRepost()
  }

  render() {
    return(
      <article className="update">
        <header className="update__header">
          <img className="update__profile-picture" src={this.props.media.user.profile_picture} alt="" />
          <div className="update__username">{this.props.media.user.username}</div>
          <div className="update__time">{moment.unix(this.props.media.created_time).fromNow(true)} ago</div>
        </header>
        <div className="update__body">
          <div className="update__attachment">
            <img src={this.props.media.images.standard_resolution.url} className="update__image" alt="" />
          </div>
          <div className="update__caption">{this.props.media.caption.text}</div>
        </div>
        <footer className="update__footer">
          <button className="btn btn--primary update__repost-btn" onClick={(e) => this.handleRepost(e)}>Repost</button>
        </footer>
      </article>
    )
  }

}