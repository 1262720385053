import ReactOnRails from 'react-on-rails';

import '../assets/styles/app.scss';

import 'jquery/src/jquery'
import 'jquery-ujs/src/rails'
import registerElements from '@sixoverground/tang'
import '@sixoverground/tang/lib/css/main.css'

import EditableMediaGrid from '../bundles/LinkMyPhotos/components/EditableMediaGrid/EditableMediaGrid.jsx';
import Stats from '../bundles/LinkMyPhotos/components/Stats/Stats.jsx';
import UpdateQueue from '../bundles/LinkMyPhotos/components/UpdateQueue/UpdateQueue.jsx';
import Scheduler from '../bundles/LinkMyPhotos/components/Scheduler/Scheduler.jsx';
import Toast from '../bundles/LinkMyPhotos/components/Toast/Toast.jsx';
import SearchList from '../bundles/LinkMyPhotos/components/SearchList/SearchList.jsx';
import ClickOutsideBurger from '../bundles/LinkMyPhotos/components/Burger/Burger.jsx';
import Navbar from '../bundles/LinkMyPhotos/components/Navbar/Navbar.jsx';
import ProductFields from '../bundles/LinkMyPhotos/components/ProductFields/ProductFields.jsx';

registerElements()

ReactOnRails.register({
  EditableMediaGrid, Stats, UpdateQueue, Scheduler, // YourLink, 
  Toast, SearchList, ClickOutsideBurger, Navbar,
  ProductFields
});
