import React, { PropTypes } from 'react'
import ReactOnRails from 'react-on-rails'
import css from './Scheduler.scss'
import moment from 'moment-timezone'
import request from 'axios'
import Schedule from './Schedule/Schedule.jsx'

const defaultSchedule = {
  days: ['MON', 'TUE', 'WED', 'THU', 'FRI'], 
  times: ['12:00']
}

export default class Scheduler extends React.Component {
  constructor(props, _railsContext) {
    super(props)
    this.state = {
      timezone: this.props.identity.timezone,
      schedules: (this.props.identity.schedules ? this.props.identity.schedules : [defaultSchedule])
    }
  }

  onTimezoneChange(e) {
    const requestConfig = {
      responseType: 'json',
      headers: ReactOnRails.authenticityHeaders()
    }

    let data = {
      timezone: e.target.value
    }

    request
      .patch('/app/identities/' + this.props.identity.id + '.json', data, requestConfig)
      .then((response) => {
        this.setState({
          timezone: response.data.timezone
        })
      })
      .catch(error => {
        console.log('error: ' + error)
      })
  }

  onScheduleChange(schedule, index) {
    var schedules = this.state.schedules
    schedules.splice(index, 1, schedule)

    const requestConfig = {
      responseType: 'json',
      headers: ReactOnRails.authenticityHeaders()
    }

    let data = { identity: {
        schedules: schedules
      }
    }

    request
      .patch('/app/identities/' + this.props.identity.id + '.json', data, requestConfig)
      .then((response) => {
        this.setState({
          schedules: response.data.schedules
        })
      })
      .catch(error => {
        console.log('error: ' + error)
      })
  }

  render() {
    return(
      <div className="scheduler">
        <div className="scheduler__timezone">
          <label htmlFor="timezone">Schedule Timezone</label>
          <select id="timezone" value={this.state.timezone} onChange={(e) => this.onTimezoneChange(e)}>
            {this.props.timezones.map((timezone) =>
              <option key={timezone.name} value={timezone.value}>(GMT{timezone.offset}) {timezone.name}</option>
            )}
          </select>
          <div className="form-text">The local time here is currently {moment().tz(this.state.timezone).format('h:mm A')}</div>
        </div>
        <div className="scheduler__schedules">
          {this.state.schedules.map((schedule, index) =>
            <Schedule key={index} schedule={schedule} onChange={(e) => this.onScheduleChange(e, index)} />
          )}
        </div>
      </div>
    )
  }

}
