import React, { PropTypes } from 'react'
import moment from 'moment-timezone';
import css from './SentUpdate.scss'
import ClickOutsideSentUpdateNav from './SentUpdateNav/SentUpdateNav.jsx'

export default class SentUpdate extends React.Component {
  constructor(props, _railsContext) {
    super(props)
  }

  render() {
    return(
      <article className="update">
        <header className="update__header">{moment(this.props.update.due_at).tz(this.props.identity.timezone).format('h:mm a dddd, MMMM Do')}</header>
        <div className="update__body">
          <div className="update__attachment">
            <img src={this.props.update.image.url} className="update__image" alt="" />
          </div>
          <div className="update__caption">{this.props.update.caption}</div>
        </div>
        {/*<ClickOutsideSentUpdateNav onDelete={(e) => this.props.onDelete(this.props.update)} />*/}
      </article>
    )
  }

}