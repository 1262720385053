import React, { PropTypes } from 'react'
import './Navbar.scss'
import ClickOutsideIdentitiesNav from '../IdentitiesNav/IdentitiesNav.jsx'
import ClickOutsideNotificationsNav from '../NotificationsNav/NotificationsNav.jsx'
import ClickOutsideUserNav from '../UserNav/UserNav.jsx'
import Headroom from 'react-headroom'
import Sidebar from '../Sidebar/Sidebar.jsx'

export default class Navbar extends React.Component {  
  constructor(props, _railsContext) {
    super(props)
  }
 
  render() {
    return(
      <Headroom disableInlineStyles>
        <div className="navbar">
          <div className="navbar__container">
            <a href="/"  className='account-back'>
              <i className="fa fa-arrow-left" aria-hidden="true"></i>
              <span className="account-back__label">Link My Photos</span>
            </a>

            <ClickOutsideIdentitiesNav 
              currentUser={this.props.currentUser}
              currentIdentity={this.props.currentIdentity}
              otherIdentities={this.props.otherIdentities}
              />

            <div className="navbar__action-items">
              <ClickOutsideNotificationsNav 
                notifications={this.props.notifications} />

              <ClickOutsideUserNav
                currentUser={this.props.currentUser}
                currentIdentity={this.props.currentIdentity}
                admin={this.props.admin}
                />
            </div>
          </div>
        </div>
        <Sidebar activeIndex={this.props.activeIndex}/>
      </Headroom>
    )
  }
}
