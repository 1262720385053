import React, { PropTypes } from 'react'
import ReactOnRails from 'react-on-rails'
import request from 'axios'
import onClickOutside from 'react-onclickoutside'
import css from './RepostSharer.scss'
import update from 'immutability-helper'
class RepostSharer extends React.Component {  
  constructor(props, _railsContext) {
    super(props)
    this.state = { 
      update: {
        caption: this.props.media.caption.text + ' #repost @' + this.props.media.user.username + ' via @linkmyphotos'
      }
    }
  }

  componentDidMount() {
    this.caption.focus()
    this.caption.style.height = 'auto'
    this.caption.style.height = this.caption.scrollHeight + 'px'
  }

  handleCaptionChange(e) {
    const changedUpdate = update(this.state.update, {caption: {$set: e.target.value}})
    this.setState({
      update: changedUpdate
    })
    e.target.style.height = 'auto'
    e.target.style.height = e.target.scrollHeight + 'px'
  }

  saveUpdate(e) {
    const requestConfig = {
      responseType: 'json',
      headers: ReactOnRails.authenticityHeaders()
    }

    var update = this.state.update

    var data = new FormData()
    data.append('caption', update.caption)
    data.append('repost_image', this.props.media.images.standard_resolution.url)
    data.append('repost_id', this.props.media.id)
    data.append('repost_profile_picture', this.props.media.user.profile_picture)
    data.append('repost_username', this.props.media.user.username)

    request
      .post('/app/updates.json', data, requestConfig)
      .then((response) => {
        this.props.onCreate(response.data)
      })
      .catch(error => {
        console.log('error: ' + error)
      })
  }

  cancelUpdate(e) {
    e.preventDefault()
    this.props.hide()
  }

  render() {
    const isValid = this.state.update.caption.length > 0
    const saveLabel = 'Repost to queue'
    return(
      <div className="sharer">
        <div className="sharer__header">
          <img className="sharer__profile-picture" src={this.props.identity.profile_picture} alt="" />
          <i className="fa fa-chevron-left sharer__repost-arrow" aria-hidden="true"></i>
          <img className="sharer__profile-picture" src={this.props.media.user.profile_picture} alt="" />
          <span className="sharer__username">{this.props.media.user.username}</span>
          <a href="#" className="sharer__cancel" onClick={(e) => this.cancelUpdate(e)}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </a>
        </div>
        <div className="sharer__content">
          <div className="sharer__composer sharer__composer--photo">
            <textarea ref={(caption) => this.caption = caption} 
                      placeholder="Write a caption..." 
                      className="sharer__caption" 
                      value={this.state.update.caption} 
                      onChange={(e) => this.handleCaptionChange(e)}
                      rows="1" />
          </div>
          <div className="sharer__attachment">
            <div className="sharer-thumbnail">
              <img src={this.props.media.images.standard_resolution.url} className="sharer-thumbnail__image" alt="" />
            </div>
          </div>
        </div>
        <div className="sharer__actions">
          <button className="btn btn--primary sharer__share-button" 
                  onClick={(e) => this.saveUpdate(e)}
                  disabled={!isValid}>{saveLabel}</button>
        </div>
      </div>
    )
  }

  handleClickOutside(evt) {
    this.props.hide()
  }

}

export default onClickOutside(RepostSharer)