import React, { PropTypes } from 'react'
import css from './Identity.scss'

export default class Identity extends React.Component {  
  constructor(props, _railsContext) {
    super(props)
  }

  render() {
    const url = (this.props.identity.avatar && this.props.identity.avatar.url) ? this.props.identity.avatar.url : this.props.identity.profile_picture
    return(
      <li className="identities-nav__menu-item">
        <a className="identities-nav__link" href={'/app/identities/' + this.props.identity.username}>
          {url &&
            <img className="identities-nav__profile-picture" src={url} alt={this.props.identity.username}/>
          }
          <span>{this.props.identity.username}</span>
        </a>
      </li>
    )
  }

}
