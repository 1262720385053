import React, { PropTypes } from 'react';
import update from 'immutability-helper';
import css from './EditableMediaGrid.scss'
import request from 'axios'
import moment from 'moment'
import $ from 'jquery'
import ClickOutsideMediaEditor from './MediaEditor/MediaEditor.jsx'
import MediaGrid from '../MediaGrid/MediaGrid.jsx'

const perPage = 18

export default class EditableMediaGrid extends React.Component {
  constructor(props, _railsContext) {
    super(props);
    this.state = {
      page: 1,
      identity: this.props.identity,
      media: this.props.media,
      editing: this.props.editing,
      hasMore: this.props.media === perPage,
      isRefreshingMedia: this.props.identity.refreshing_media,
      isLoadingMedia: this.props.identity.loading_media
    };
  }

  componentDidMount() {
    // poll for loading / fetching
    if (this.props.identity.loading_media || this.props.identity.refreshing_media) {
      this.poll()
    }
  }

  // Poll the identity to see if its done refreshing and loading
  poll() {
    const requestConfig = {
      responseType: 'json',
      headers: ReactOnRails.authenticityHeaders()
    }

    request
      .get('/app/identities/' + this.props.identity.id + '/poll.json', requestConfig)
      .then((response) => {
        console.log('polled')
        const newMedia = this.mergeMedia(this.state.media, response.data.media)
        this.setState({
          media: newMedia,
          isRefreshingMedia: response.data.refreshing_media,
          isLoadingMedia: response.data.loading_media
        })
        console.log('loading media=' + response.data.loading_media + ' refreshing_media=' + response.data.refreshing_media)
        if (response.data.loading_media || response.data.refreshing_media) {
          setTimeout(function() { this.poll() }.bind(this), 5000)
        }
      })
      .catch(error => {
        console.log('poll error: ' + error)
      })
  }

  loadRecent(e) {
    e.preventDefault();

    this.setState({
      isRefreshingMedia: true
    })

    const requestConfig = {
      responseType: 'json',
      headers: ReactOnRails.authenticityHeaders()
    }

    request
      .put('/app/identities/' + this.props.identity.id + '/refresh.json', null, requestConfig)
      .then((response) => {
        setTimeout(function() { this.poll() }.bind(this), 5000)
      })
      .catch(error => {
        console.log('load recent links error: ' + error)
      })
  }

  cancelRefresh(e) {
    e.preventDefault();

    this.setState({
      isRefreshingMedia: false
    })

    const requestConfig = {
      responseType: 'json',
      headers: ReactOnRails.authenticityHeaders()
    }

    request
      .put('/app/identities/' + this.props.identity.id + '/cancel_refresh.json', null, requestConfig)
      .then((response) => {
        console.log('successful cancel refresh', response)
      })
      .catch(error => {
        console.log('cancel refresh error', error)
      })
  }

  loadMore(e) {
    e.preventDefault();
    this.fetchPage(this.state.page + 1);
  }

  mergeMedia(old, updated) {
    var o = {}
    old.forEach(function(v) {
      o[v.id] = v
    })
    updated.forEach(function(v) {
      o[v.id] = v
    })
    var r = []
    for (var p in o) {
      if (o.hasOwnProperty(p)) {
        r.push(o[p])
      }
    }
    const sorted = r.sort(function(a, b) {
      return new Date(b.created_time) - new Date(a.created_time)
    })
    return sorted
  }

  fetchPage(page) {
    const requestConfig = {
      responseType: 'json',
      headers: ReactOnRails.authenticityHeaders()
    }

    request
      .get('/app/link.json?page=' + page, requestConfig)
      .then((response) => {
        if (response.data.length > 0) {
          const newMedia = this.mergeMedia(this.state.media, response.data)
          this.setState({
            page: page,
            media: newMedia
          })
        }

        if (response.data.length < perPage) {
          if (this.props.identity.media_loaded) {
            this.setState({
              status: 'done'
            })
          } else {
            this.setState({
              status: 'done'
            })
          }
        }

      })
      .catch(error => {
        console.log('load more links error: ' + error)
      })

  }

  handleMediaClick(media) {
    this.setState({
      editing: media
    });
    this.openEditor();
  }

  // Fetch updated media from Instagram
  handleMediaRefresh(media) {    
    const editing = this.state.editing;
    var updatedMedia = update(media, {fetching: {$set: true}});
    var mediaIndex = this.state.media.findIndex(function(m) {
      return m.id == media.id;
    });
    var newMedia = update(this.state.media, {
      $splice: [[mediaIndex, 1, updatedMedia]]
    });

    var newEditing
    if (editing != null && editing.id === media.id) {
      newEditing = update(editing, {fetching: {$set: true}})
    } else {
      newEditing = editing
    }

    this.setState({
      media: newMedia,
      editing: newEditing
    });

    const requestConfig = {
      responseType: 'json',
      headers: ReactOnRails.authenticityHeaders()
    }

    request
      .put('/app/media/' + media.id + '/refresh.json', null, requestConfig)
      .then((response) => {
        setTimeout(function() { this.pollMedia(media) }.bind(this), 5000)
      })
      .catch(error => {
        console.log('refresh media error: ' + error)
      })

  }

  // Poll the media to see if it's done fetching
  pollMedia(media) {
    const _this = this

    const requestConfig = {
      responseType: 'json',
      headers: ReactOnRails.authenticityHeaders()
    }

    request
      .get('/app/media/' + media.id + '/poll.json', requestConfig)
      .then((response) => {
        console.log(`media polled fetching=${response.data.fetching} image_thumbnail_url=${response.data.image_thumbnail_url} image.standard_resolution.url=${response.data.image.standard_resolution.url}`)

        const data = response.data
        const editing = this.state.editing;

        var updatedMedia = update(media, 
          {image_standard_resolution_url: {$set: data.image_standard_resolution_url}},
          {image_thumbnail_url: {$set: data.image_thumbnail_url}},
          {fetching: {$set: data.fetching}}
        );
        updatedMedia.image_thumbnail_url = null
        updatedMedia.image = data.image
        if (updatedMedia.image && updatedMedia.image.standard_resolution && updatedMedia.image.standard_resolution.url) {
          updatedMedia.image.standard_resolution.url = `${updatedMedia.image.standard_resolution.url}?${Date.now()}`
        }

        var mediaIndex = _this.state.media.findIndex(function(m) {
          return m.id == media.id;
        });
        var newMedia = update(_this.state.media, {
          $splice: [[mediaIndex, 1, updatedMedia]]
        });

        var newEditing;
        if (editing != null && editing.id === media.id) {
          newEditing = update(editing, 
            {image_standard_resolution_url: {$set: data.image_standard_resolution_url}},
            {image_thumbnail_url: {$set: data.image_thumbnail_url}}
          );
          newEditing.image_thumbnail_url = null
          newEditing.image = data.image
          if (newEditing.image && newEditing.image.standard_resolution && newEditing.image.standard_resolution.url) {
            newEditing.image.standard_resolution.url = `${newEditing.image.standard_resolution.url}?${Date.now()}`
          }
          newEditing.fetching = data.fetching
        } else {
          newEditing = editing
        }

        console.log('updating editing:', newEditing)

        _this.setState({
          media: newMedia,
          editing: newEditing
        });

        if (response.data.fetching) {
          setTimeout(function() { this.pollMedia(media) }.bind(this), 5000)
        }
      })
      .catch(error => {
        console.log('poll media error: ' + error)
      })
  }

  openEditor() {
    var scrollTop = $(window).scrollTop();
    var $body = $('body');
    $body.css('position', 'fixed');
    $body.css('width', '100%');
    $body.css('top', -scrollTop);
  }

  closeEditor() {
    var $body = $('body');
    var scrollTop = $body.position().top;
    $body.css('position', '');
    $body.css('width', '');
    $body.css('top', '');
    $(window).scrollTop(-scrollTop);
  }

  onMediaEditorSave(media) {
    const requestConfig = {
      responseType: 'json',
      headers: ReactOnRails.authenticityHeaders()
    }

    let data = {
      media: {
        uri: media.uri,
        link_type: media.link_type,
        // blank_target: media.blank_target
        tagged_products_attributes: media.tagged_products.map(function(tagged_product) {
          if (tagged_product.product.id) {
            return {
              id: tagged_product.id,
              _destroy: tagged_product._destroy,
              product_id: tagged_product.product.id
            }
          } else {
            return {
              id: tagged_product.id,
              _destroy: tagged_product._destroy,
              product_attributes: tagged_product.product
            }
          }
        })
      }
    }

    request
      .patch('/app/media/' + media.id + '.json', data, requestConfig)
      .then((response) => {
        const mediaIndex = this.state.media.findIndex(function(m) {
          return m.id == media.id;
        })
        const newMedia = update(this.state.media, {
          $splice: [[mediaIndex, 1, response.data]]
        })
        this.setState({
          media: newMedia,
          editing: null
        })
        this.closeEditor()
      })
      .catch(error => {
        console.log('error: ' + error)
      })
  }

  onMediaEditorCancel() {
    this.setState({
      editing: null
    })
    this.closeEditor()
  }

  onSavePayPal(paypalBusiness) {
    const requestConfig = {
      responseType: 'json',
      headers: ReactOnRails.authenticityHeaders()
    }

    let data = {
      identity: {
        paypal_business: paypalBusiness
      }
    }

    request
      .patch('/app/identities/' + this.state.identity.id + '.json', data, requestConfig)
      .then((response) => {
        this.setState({
          identity: response.data        
        })
      })
      .catch(error => {
        console.log('error: ' + error)
      })
  }

  render() {
    return(
      <div>
        <div className="editable-media-grid__controls">
          {this.state.isRefreshingMedia ?
            <a href="#" onClick={(e) => this.cancelRefresh(e)} className="btn btn-default btn-sm btn--refresh">
              <div className="loader loader--small">Loading...</div>
              <span className="editable-media-grid__cancel-btn-text">Cancel sync</span>
            </a>
          :
            <a href="#" onClick={(e) => this.loadRecent(e)} className="btn btn-default btn-sm btn--refresh"><i className="fa fa-refresh"></i> Check for new photos</a>
          }
        </div>
        <MediaGrid media={this.state.media}
                   onClick={(media) => this.handleMediaClick(media)}
                   onRefresh={(media) => this.handleMediaRefresh(media)} />
        {(this.state.status !== 'done' && this.state.status !== 'loading') && this.state.media.length >= perPage && 
          <div className="pagination"><a href="#" ref={(button) => this.button = button} className="btn btn--load-more" onClick={(e) => this.loadMore(e)}>Load more</a></div>
        }
        {this.state.isLoadingMedia &&
          <div className="loader">Loading...</div>
        }
        {this.state.editing && 
          <ClickOutsideMediaEditor 
            identity={this.state.identity} 
            media={this.state.editing} 
            products={this.props.products} 
            onSave={(e) => this.onMediaEditorSave(e)} 
            onRefresh={(e) => this.handleMediaRefresh(e)}
            onCancel={(e) => this.onMediaEditorCancel()}
            onSavePayPal={(paypalBusiness) => this.onSavePayPal(paypalBusiness)} 
            />
        }
      </div>
    );
  }

}