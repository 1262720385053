import React, { PropTypes } from 'react'
import TimePicker from '../../TimePicker/TimePicker.jsx'
import css from './Schedule.scss'

const days = [
  { name: 'Sun', value: 'SUN' },
  { name: 'Mon', value: 'MON' },
  { name: 'Tue', value: 'TUE' },
  { name: 'Wed', value: 'WED' },
  { name: 'Thu', value: 'THU' },
  { name: 'Fri', value: 'FRI' },
  { name: 'Sat', value: 'SAT' }
]

const defaultTime = '12:00'

export default class Schedule extends React.Component {
  constructor(props, _railsContext) {
    super(props)
  }

  onChangeTime(time, index) {
    var schedule = this.props.schedule
    schedule.times.splice(index, 1, time)
    this.props.onChange(schedule)
  }

  toggleDay(e, day) {
    var schedule = this.props.schedule
    const index = schedule.days.indexOf(day.value)
    if (index >= 0) {
      schedule.days.splice(index, 1)
    } else {
      schedule.days.push(day.value)
    }
    this.props.onChange(schedule)
  }

  removeTime(e, index) {
    e.preventDefault()
    var schedule = this.props.schedule
    schedule.times.splice(index, 1)
    this.props.onChange(schedule)
  }

  addTime(e) {
    e.preventDefault()
    var schedule = this.props.schedule
    if (schedule.times == null) schedule.times = [defaultTime]
    schedule.times.push(defaultTime)
    this.props.onChange(schedule)
  }

  render() {
    const times = this.props.schedule.times ? this.props.schedule.times : ['12:00']
    return(
      <div className="schedule">
        <label>Days to post</label>
        <div className="schedule__days">
          {days.map((day) =>
            <button 
              key={day.value} 
              className={'schedule__day-button' + (this.props.schedule.days.includes(day.value) ? ' schedule__day-button--active': ' schedule__day-button--inactive')} 
              type="button"
              onClick={(e) => this.toggleDay(e, day)}>{day.name}</button>
          )}
        </div>
        <label>Times to post</label>
        <div className="schedule__times">
          {times.map((time, index) =>
            <div key={index} className="schedule__time">
              <TimePicker time={time} onChange={(e) => this.onChangeTime(e, index)} />
              {times.length > 1 &&
                <a className="schedule__remove-time-button" href="#" onClick={(e) => this.removeTime(e, index)}>
                  <i className="fa fa-times" aria-hidden="true"></i>
                </a>
              }
            </div>
          )}
        </div>
        <a className="schedule__add-time-button" href="#" onClick={(e) => this.addTime(e)}>
          <i className="fa fa-plus" aria-hidden="true"></i>
          <span>Add a time</span>
        </a>
      </div>
    )
  }

}
