import React, { PropTypes } from 'react'
import Autosuggest from 'react-autosuggest'
import css from './ProductSelect.scss'

const getSuggestionValue = suggestion => suggestion.name

const renderSuggestion = suggestion => (
  <div>{suggestion.name}</div>
)

export default class ProductSelect extends React.Component {
  constructor(props, _railsContext) {
    super(props)
    this.state = {
      value: '',
      suggestions: []
    }
  }

  getSuggestions(value) {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    return inputLength === 0 ? [] : this.props.products.filter(product =>
      product.name.toLowerCase().slice(0, inputLength) === inputValue
    )
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    })
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    })
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    })
  }

  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex }) => {
    this.props.onChange(suggestion)
  }

  render() {
    const { value, suggestions } = this.state

    const inputProps = {
      placeholder: 'Type a product name',
      value,
      onChange: this.onChange
    }

    return(
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={this.onSuggestionSelected}
      />
    )
  }
}
