import React, { PropTypes } from 'react'
import css from './SearchList.scss'
import request from 'axios'
import SearchItem from './SearchItem/SearchItem.jsx'
import SearchResult from './SearchResult/SearchResult.jsx'
import SearchMediaItem from './SearchMediaItem/SearchMediaItem.jsx'
import ClickOutsideRepostSharer from './RepostSharer/RepostSharer.jsx'
import Modal from 'react-modal'
import update from 'immutability-helper'
import DebounceInput from 'react-debounce-input'

Modal.setAppElement('body')

export default class SearchList extends React.Component {
  constructor(props, _railsContext) {
    super(props)
    var query = this.props.query
    if (query == null) query = ''
    this.state = {
      permissionsRequired: this.props.permissionsRequired, // unauthorized
      savedSearches: this.props.searches, // account saved searches
      query: query, // text query
      searchResults: [], // search results from query
      currentSearch: null,
      media: [], // media results from search
      editing: null, // editing media to repost
      // status: 'browsing'
      searching: false,
      reposting: false
    }
  }

  handleQueryChange(e) {
    this.setState({
      query: e.target.value,
      searching: true
    })
    this.performSearch()
  }

  performSearch() {
    const requestConfig = {
      responseType: 'json',
      headers: ReactOnRails.authenticityHeaders()
    }

    request
      .get('/app/searches/new.json?q=' + this.state.query, requestConfig)
      .then((response) => {
        var users = response.data.users
        if (users == null) users = []
        var tags = response.data.tags
        if (tags == null) tags = []

        let mappedUsers = users.map((u) => { return { category: 'user', name: u.username, hash: 'user_' + u.username, full_name: u.full_name, profile_picture: u.profile_picture, uid: u.id } })
        let mappedTags = tags.map((t) => { return { category: 'tag', name: t.name, hash: 'tag_' + t.name, media_count: t.media_count } })
        let searchResults = mappedUsers.concat(mappedTags).sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        })

        this.setState({
          searchResults: searchResults,
          searching: false
        })
      })
      .catch(error => {
        console.log('search error: ' + error)
        if (error.response.status == 401) {
          this.setState({
            permissionsRequired: ['public_content']
          })
        }
        this.setState({
          searching: false
        })
      })
  }

  onCreate(response) {
    // TODO: maybe save reposted ids so we know what's already reposted
    this.setState({
      editing: null
      // status: 'reposting'
    })
    this.closeEditor()
  }

  handleRepost(media) {
    this.setState({
      editing: media
      // status: 'editing'
    })
    this.openEditor()
  }

  closeModal(e) {
    this.setState({
      // status: 'reposting',
      editing: null
    })
    this.closeEditor()
  }

  openEditor() {
    var scrollTop = $(window).scrollTop();
    var $body = $('body');
    $body.css('position', 'fixed');
    $body.css('width', '100%');
    $body.css('top', -scrollTop);
  }

  closeEditor() {
    var $body = $('body');
    var scrollTop = $body.position().top;
    $body.css('position', '');
    $body.css('width', '');
    $body.css('top', '');
    $(window).scrollTop(-scrollTop);
  }

  clickSearchItem(searchItem) {
    if (searchItem.category == 'user') {
      this.searchUser(searchItem)
    } else {
      this.searchTag(searchItem)
    }
  }

  clickSearchResult(searchResult) {
    if (searchResult.category == 'user') {
      this.searchUser(searchResult)
    } else {
      this.searchTag(searchResult)
    }
  }

  searchUser(object) {
    this.setState({
      currentSearch: object,
      reposting: true
    })

    const requestConfig = {
      responseType: 'json',
      headers: ReactOnRails.authenticityHeaders()
    }

    request
      .get('/app/searches/users/' + object.uid, requestConfig)
      .then((response) => {
        this.setState({
          // currentSearch: object,
          media: response.data,
          reposting: false
        })

      })
      .catch(error => {
        if (error.response.status == 401) {
          this.setState({
            permissionsRequired: ['public_content']
          })
        }
        this.setState({
          reposting: false
        })
      })
  }

  searchTag(object) {
    this.setState({
      currentSearch: object,
      reposting: true
    })

    const requestConfig = {
      responseType: 'json',
      headers: ReactOnRails.authenticityHeaders()
    }

    request
      .get('/app/searches/tags/' + object.name, requestConfig)
      .then((response) => {
        this.setState({
          // currentSearch: object,
          media: response.data,
          reposting: false
        })

      })
      .catch(error => {
        console.log('search error: ' + error)
        if (error.response.status == 401) {
          this.setState({
            permissionsRequired: ['public_content']
          })
        }
        this.setState({
          reposting: false
        })
      })
  }

  cancelSearch(e) {
    e.preventDefault()
    this.setState({
      query: ''
    })
  }

  goBack(e) {
    e.preventDefault()
    this.setState({
      currentSearch: null
    })
  }

  savedSearchFromObject(object) {
    if (object.category == 'user') {
      return this.state.savedSearches.filter((search) =>
        search.category == 'user' && search.name == object.name
      )[0]
    } else if (object.category == 'tag') {
      return this.state.savedSearches.filter((search) =>
        search.category == 'tag' && search.name == object.name
      )[0]
    }
    return null
  }

  toggleSaved(e) {
    let currentSearch = this.state.currentSearch

    const requestConfig = {
      responseType: 'json',
      headers: ReactOnRails.authenticityHeaders()
    }

    if (e.target.checked) {
      // create search
      
      let data = {
        name: currentSearch.name,
        category: currentSearch.category,
        uid: currentSearch.uid,
        full_name: currentSearch.full_name,
        profile_picture: currentSearch.profile_picture,
        media_count: currentSearch.media_count
      }

      request
        .post('/app/searches', data, requestConfig)
        .then((response) => {
          const newSavedSearches = update(this.state.savedSearches, {
            $push: [response.data]
          })

          this.setState({
            savedSearches: newSavedSearches
          })
          
        })
        .catch(error => {
          console.log('search error: ' + error)
          if (error.response.status == 401) {
            this.setState({
              permissionsRequired: ['public_content']
            })
          }
        })
    } else {
      // delete search
      let savedSearch = this.savedSearchFromObject(currentSearch)

      request
        .delete('/app/searches/' + savedSearch.id, requestConfig)
        .then((response) => {
          const index = this.state.savedSearches.findIndex(function(s) {
            return s.id === currentSearch.id;
          })
          const searches = update(this.state.savedSearches, {
            $splice: [[index, 1]]
          })
          this.setState({
            savedSearches: searches
          })
          
        })
        .catch(error => {
          console.log('delete error: ' + error)
          if (error.response.status == 401) {
            this.setState({
              permissionsRequired: ['public_content']
            })
          }
        })
    }
  }

  // startSearch(e) {
  //   e.preventDefault()
  //   this.queryInput.focus()
  // }

  render() {
    const searchSaved = this.state.currentSearch != null && this.savedSearchFromObject(this.state.currentSearch) != null
    const recommendedTags = this.props.recommendedTags.map(function(tag) { return {category: 'tag', name: tag} })
    return(
      <div className="search-list">
        <div className="search-list__input-area">
          {this.state.currentSearch == null &&
            <div className="search-list__input-wrapper">
              <DebounceInput 
                type="search" 
                minLength={2}
                debounceTimeout={300}
                value={this.state.query} 
                onChange={(e) => this.handleQueryChange(e)}
                placeholder="Search for @usernames or #hashtags"
                ref={(input) => { this.queryInput = input; }}
                className="search-list__input" />
              {this.state.searching &&
                <i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
              }
            </div>
          }

          {this.state.currentSearch == null && this.state.query.length > 0 &&
            <a href="#" className="search-list__cancel" onClick={(e) => this.cancelSearch(e)}>
              <i className="fa fa-times-circle" aria-hidden="true"></i>
            </a>
          }
        </div>

        {this.state.currentSearch != null &&
          <div className="search-list__search-results-header">
            <div className="search-list__search-results-details">
              <a className="search-list__back" href="#" onClick={(e) => this.goBack(e)}>
                <i className="fa fa-arrow-left" aria-hidden="true"></i>
                <span className="search-list__back-label">Back to search</span>
              </a>
              <div className="search-list__query">{this.state.currentSearch.name}</div>
            </div>
            <label className="search-list__saved">
              <input aria-labelledby="saved-label" className="search-list__checkbox" type="checkbox" checked={searchSaved} onChange={(e) => this.toggleSaved(e)}/>
              <span className="saved-label">Save this search</span>
            </label>
          </div>
        }

        {this.state.permissionsRequired.length > 0 &&
          <div>
            <p>Please allow instagram to search for tags and users on your behalf.</p>
            <a href="/users/auth/instagram?scope=public_content&page=search" className="btn btn--instagram" data-turbolinks="false">
              <i className="fa fa-instagram" aria-hidden="true"></i>
              <span className="label">Sign in with Instagram</span>
            </a>
          </div>
        }

        {this.state.query.length == 0 && this.state.currentSearch == null &&
          this.state.savedSearches.length > 0 &&
          <div className="search-list__section-title">Saved</div>
        }
        {this.state.query.length == 0 && this.state.currentSearch == null &&
          this.state.savedSearches.map((searchItem) =>
            <SearchItem key={searchItem.id} search={searchItem} onClick={(e) => this.clickSearchItem(searchItem)} />
          )
        }
        {this.state.query.length == 0 && this.state.currentSearch == null && 
          this.state.savedSearches.length == 0 &&
          <div>
            <h2>Discover Instagram content to repost</h2>
            <p>Saved searches for @usernames and #hashtags will appear here. Try typing some of your interests in the search field above to get started.</p>
          </div>
        }
        {this.state.query.length == 0 && this.state.currentSearch == null &&
          recommendedTags.length > 0 &&
          <div className="search-list__section-title">Recommended</div>
        }
        {this.state.query.length == 0 && this.state.currentSearch == null &&
          recommendedTags.map((searchResult) =>
            <SearchResult key={searchResult.name} searchResult={searchResult} onClick={(e) => this.clickSearchResult(searchResult)} />
          )
        }

        {this.state.query.length > 0 && this.state.currentSearch == null &&
          this.state.searchResults.map((searchResult) =>
            <SearchResult key={searchResult.hash} searchResult={searchResult} onClick={(e) => this.clickSearchResult(searchResult)} />
          )
        }


        {this.state.reposting &&
          <div className="search-list__spinner">
            <i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
          </div>
        }

        {this.state.currentSearch != null &&
          this.state.media.map((media) =>
            <SearchMediaItem key={media.id} media={media} onRepost={(e) => this.handleRepost(media)} />
          )
        }

        <Modal
          isOpen={this.state.editing != null}
          onRequestClose={this.closeModal}
          contentLabel="Update"
          className="update-queue__modal-content"
          overlayClassName="update-queue__modal-overlay">
          <div className="update-queue__modal-sharer">
            <ClickOutsideRepostSharer onCreate={response => this.onCreate(response)} 
                                media={this.state.editing}
                                identity={this.props.identity}
                                hide={(e) => this.closeModal(e)} />
          </div>
        </Modal>
      </div>
    )
  }

}
