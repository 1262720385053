import css from "./Info.scss";
import React, { PropTypes } from "react";
import ReactOnRails from "react-on-rails";
// import onClickOutside from 'react-onclickoutside'

export default class Info extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      toggle: false,
    };
  }

  toggleInfo(e) {
    e.preventDefault();
    this.setState({
      open: !this.state.open,
    });
  }

  render() {
    return (
      <div className="info-button">
        <a className="btn-info" onClick={(e) => this.toggleInfo(e)}>
          <i className="fa fa-question-circle" aria-hidden="true"></i>
        </a>
        {this.state.open && (
          <div className="info-box">
            <div className="text">
              <p>
                <b>Views:</b> The number of posts that were viewed by visitors
                to your Link My Photos page during this time period.
              </p>
              <p>
                <b>Clicks:</b> The number of posts that were clicked on by
                visitors to your Link My Photos page during this time period.
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}
