import React, { PropTypes } from 'react'
import moment from 'moment-timezone'
import css from './TimePicker.scss'

const pad = (n, width, z) => {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

const splitTime = (time) => {
  const elements = time.split(':')
  const hour24 = parseInt(elements[0], 10)
  const minute = elements[1]
  const meridiem = hour24 < 12 ? 'AM' : 'PM'
  var hour = hour24
  if (hour24 === 0) {
    hour = 12
  } else if (hour24 > 12) {
    hour = hour24 - 12
  }
  hour = pad(hour, 2)
  return [hour, minute, meridiem]
}

const joinTime = (hour, minute, meridiem) => {
  var hourInt = parseInt(hour, 10)
  if (meridiem === 'AM' && hourInt === 12) {
    hourInt = 0
  } else if (meridiem === 'PM' && hourInt < 12) {
    hourInt = hourInt + 12
  }
  return pad(hourInt, 2) + ':' + minute
}

export default class TimePicker extends React.Component {  
  constructor(props, _railsContext) {
    super(props)

    // this.state = {
    //   hour: props.time.format('hh'),
    //   minute: props.time.format('mm'),
    //   meridiem: props.time.format('A')
    // }
  }

  

  handleHourChange(e) {
    const hour = e.target.value
    // this.setState({
    //   hour: hour
    // })

    const elements = splitTime(this.props.time)
    // this.handleChange(hour, this.state.minute, this.state.meridiem)
    this.handleChange(hour, elements[1], elements[2])
  }

  handleMinuteChange(e) {
    const minute = e.target.value
    // this.setState({
    //   minute:minute
    // })

    const elements = splitTime(this.props.time)
    // this.handleChange(this.state.hour, minute, this.state.meridiem)
    this.handleChange(elements[0], minute, elements[2])
  }

  handleHMeridiemChange(e) {
    const meridiem = e.target.value
    // this.setState({
    //   meridiem: meridiem
    // })
    const elements = splitTime(this.props.time)
    // this.handleChange(this.state.hour, this.state.minute, meridiem)
    this.handleChange(elements[0], elements[1], meridiem)
  }

  handleChange(hour, minute, meridiem) {
    // var hourInt = parseInt(hour, 10)
    // if (meridiem === 'PM') {
    //   hourInt = 12 + hourInt
    //   if (hourInt >= 24) hourInt = 0
    // }

    // var time = this.props.time
    // time.set({ hour: hourInt, minute: parseInt(minute, 10)})

    // this.props.onChange(time)
    this.props.onChange(joinTime(hour, minute, meridiem))
  }

  render() {
    var hours = []
    for (var i = 0; i < 12; i++) {
      hours.push(pad(i + 1, 2))
    }
    var minutes = []
    for (var i = 0; i < 60; i++) {
      minutes.push(pad(i, 2))
    }

    const elements = splitTime(this.props.time)

    return(
      <div className="time-picker">
        <select className="time-picker__select" value={elements[0]} onChange={(e) => this.handleHourChange(e)}>
          {hours.map(hour => (
            <option key={hour}>{hour}</option>
          ))}
        </select>
        :
        <select className="time-picker__select" value={elements[1]} onChange={(e) => this.handleMinuteChange(e)}>
          {minutes.map(minute => (
            <option key={minute}>{minute}</option>
          ))}
        </select>
        <select className="time-picker__select" value={elements[2]} onChange={(e) => this.handleHMeridiemChange(e)}>
          <option>AM</option>
          <option>PM</option>
        </select>
      </div>
    )
  }
}