import React, { PropTypes } from 'react'
import css from './IdentitiesNav.scss'
import Identity from './Identity/Identity.jsx';
import onClickOutside from 'react-onclickoutside'

class IdentitiesNav extends React.Component {  
  constructor(props, _railsContext) {
    super(props)
    this.state = {
      open: false
    }
  }

  toggleNav(e) {
    e.preventDefault()
    this.setState({
      open: !this.state.open
    })
  }

  render() {
    var planId = 'free'
    const sub = this.props.currentUser.subscription
    if (sub) {
      const plan = sub.plan
      if (plan) {
        planId = plan.stripe_id
      }
    }
    var arrow = <i className="fa fa-chevron-down" aria-hidden="true"></i>
    if (this.state.open) {
      arrow = <i className="fa fa-chevron-up" aria-hidden="true"></i>      
    }

    let url = null
    if (this.props.currentIdentity) {
      url = (this.props.currentIdentity.avatar && this.props.currentIdentity.avatar.url) ? this.props.currentIdentity.avatar.url : this.props.currentIdentity.profile_picture
    }

    return(
      <div className="identities-nav">
        {this.props.currentIdentity &&
          <a className="identities-nav__toggle" href={'/app/identities/' + this.props.currentIdentity.username} onClick={(e) => this.toggleNav(e)}>
            {url &&
              <img className="identities-nav__profile-picture" src={url} alt={this.props.currentIdentity.username}/>
            }
            <span>{this.props.currentIdentity.username}</span>
            {arrow}
          </a>
        }
        {this.state.open && 
          <div className="identities-nav__popover">
            <ul className="identities-nav__menu">
              {this.props.otherIdentities.map((identity) =>
                <Identity identity={identity} key={identity.id} />
              )}
              <li className="identities-nav__divider"></li>
              <li className="identities-nav__menu-item">
                <a className="identities-nav__link" href="/app/identities">
                  <span>Manage accounts</span>
                </a>
              </li>
            </ul>
          </div>
        }
      </div>
    )
  }

  handleClickOutside(evt) {
    this.setState({
      open: false
    })
  }
}

export default onClickOutside(IdentitiesNav)