import React, { PropTypes } from 'react'
import css from './Sidebar.scss'

export default class Sidebar extends React.Component {  
  constructor(props, _railsContext) {
    super(props)
  }
 
  render() {
    return(
      <div className="sidebar">
        <div className="sidebar__container">
          <ul className="sidebar__list">
            <li className="sidebar__list-item">
              <a href="/app/link" className={'sidebar__link' + (this.props.activeIndex === 0 ? ' sidebar__link--active' : '')}>
                <i className="fa fa-hand-pointer-o fa-fw" aria-hidden="true"></i>
                <span className="sidebar__label">Link</span>
              </a>
            </li>
            <li className="sidebar__list-item">
              <a href="/app/schedule" className={'sidebar__link' + (this.props.activeIndex === 1 ? ' sidebar__link--active' : '')}>
                <i className="fa fa-calendar fa-fw" aria-hidden="true"></i>
                <span className="sidebar__label">Schedule</span>
              </a>
            </li>
            {/*
            <li className="sidebar__list-item">
              <a href="/app/searches" className={'sidebar__link' + (this.props.activeIndex === 2 ? ' sidebar__link--active' : '')}>
                <i className="fa fa-compass fa-fw" aria-hidden="true"></i>
                <span className="sidebar__label">Discover</span>
                <span className="badge" title="Beta"><span className="badge__greek">β</span><span className="badge__label">Beta</span></span>
              </a>
            </li>
            */}
            <li className="sidebar__list-item">
              <a href="/app/orders" className={'sidebar__link' + (this.props.activeIndex === 2 ? ' sidebar__link--active' : '')}>
                <i className="fa fa-shopping-bag fa-fw" aria-hidden="true"></i>
                <span className="sidebar__label">Sell</span>
              </a>
            </li>
            <li className="sidebar__list-item">
              <a href="/app/stats" className={'sidebar__link' + (this.props.activeIndex === 3 ? ' sidebar__link--active' : '')}>
                <i className="fa fa-bar-chart fa-fw" aria-hidden="true"></i>
                <span className="sidebar__label">Analyze</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}
