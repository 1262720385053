import React, { PropTypes } from 'react'
import css from './SentUpdateNav.scss'
import onClickOutside from 'react-onclickoutside'

class SentUpdateNav extends React.Component {
  constructor(props, _railsContext) {
    super(props)
    this.state = {
      open: false
    }
  }

  toggleNav(e) {
    e.preventDefault()
    this.setState({
      open: !this.state.open
    })
  }

  onDelete(e) {
    e.preventDefault()
    this.props.onDelete(e)
  }

  render() {
    return(
      <div className="update-nav">
        <a className="update-nav__toggle" href="#" onClick={(e) => this.toggleNav(e)}>
          <i className="fa fa-chevron-down" aria-hidden="true"></i>
        </a>
        {this.state.open && 
          <ul className="update-nav__menu">
            <li><a className="update-nav__link" href="#">Copy to queue</a></li>
            <li><a className="update-nav__link" href="#">Share now</a></li>
            <li><a className="update-nav__link" href="#" onClick={(e) => this.onDelete(e)}>Delete</a></li>
          </ul>
        }
      </div>
    )
  }

  handleClickOutside(evt) {
    this.setState({
      open: false
    })
  }
}

export default onClickOutside(SentUpdateNav)