import React, { PropTypes } from 'react'
import update from 'immutability-helper'
import css from './MediaEditor.scss'
import ProductSelect from './ProductSelect/ProductSelect.jsx'
import ProductFields from '../../ProductFields/ProductFields.jsx'
import onClickOutside from 'react-onclickoutside'

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
});

class MediaEditor extends React.Component {
  constructor(props, _railsContext) {
    super(props)
    this.state = {
      media: this.props.media,
      newProduct: undefined,
      suggestions: [],
      paypalBusiness: this.props.identity.paypal_business
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // if (this.uriField != null) {
    //   this.uriField.focus()
    // }
  }

  onUriChange(e) {
    const uri = e.target.value
    const updatedMedia = update(this.state.media, {uri: {$set: uri}});
    this.setState({
      media: updatedMedia
    })
    if (!updatedMedia) {
      this.props.onCancel()
    }
  }

  onUriKeyUp(e) {
    // if (e.key == 'Enter') {
    //   this.saveMedia()
    // }
  }

  onProductChange(e) {
    const productId = e.target.value
    if (productId === 'new') {
      this.setState({ newProduct: {
        name: '',
        amount: 0
      }})
    } else if (productId) {
      const product = this.props.products.find((product) => product.id === parseInt(productId))
      this.onProductSelectChange(product)
    }
  }

  onProductNameChange(e) {
    const name = e.target.value
    const updatedNewProduct = update(this.state.newProduct, {name: {$set: name}})
    this.setState({ newProduct: updatedNewProduct })
  }

  onProductAmountChange(event, maskedvalue, floatvalue) {
    const amount = floatvalue * 100
    // const amount = Number(currency.replace(/[^0-9\.-]+/g,""))
    // if (this.state.newProduct) {
      const updatedNewProduct = update(this.state.newProduct, {amount: {$set: amount}})      
      this.setState({ newProduct: updatedNewProduct })
    // } else {
    //   this.setState({ newProduct: { amount: amount } })
    // }
  }

  cancelNewProduct() {
    this.setState({ 
      newProduct: undefined
    })
  }

  saveNewProduct() {
    const newProduct = this.state.newProduct
    const media = this.state.media
    const taggedProducts = [{product: newProduct}]
    const updatedMedia = update(media, {tagged_products: {$set: taggedProducts}})
    this.setState({ 
      newProduct: undefined,  
      media: updatedMedia 
    })
  }

  removeProduct(e) {
    e.preventDefault()
    const media = this.state.media
    const taggedProduct = media.tagged_products[0]
    const updatedTaggedProduct = update(taggedProduct, {_destroy: {$set: true}})
    const updatedMedia = update(media, {tagged_products: {$set: [updatedTaggedProduct]}})
    this.setState({ media: updatedMedia })
  }

  saveMedia() {
    this.props.onSave(this.state.media)
  }

  cancelEditing(e) {
    e.preventDefault()
    this.props.onCancel()
  }

  clickInside(e) {
    e.stopPropagation()
  }

  onTargetChange(e) {
    var media = update(this.state.media, {blank_target: {$set: e.target.checked}})
    this.setState({ media: media })
  }

  setLinkType(e) {
    const linkType = e.target.value
    const media = this.state.media
    const updatedMedia = update(this.state.media, {link_type: {$set: linkType}})
    this.setState({ media: updatedMedia })
  }

  onProductSelectChange(product) {
    const media = this.state.media
    var taggedProducts = media.tagged_products
    if (taggedProducts.length > 0) {
      taggedProducts[0]._destroy = true
    }
    const taggedProduct = {product: product}
    taggedProducts.push(taggedProduct)
    const updatedMedia = update(media, {tagged_products: {$set: taggedProducts}})
    this.setState({ 
      media: updatedMedia 
    })
  }

  addNewProduct(e) {
    e.preventDefault()
    this.setState({ newProduct: {
      name: '',
      amount: 0
    }})
  }

  onPayPalChange(e) {
    const paypalBusiness = e.target.value
    this.setState({ paypalBusiness: paypalBusiness })
  }

  savePayPal() {
    this.props.onSavePayPal(this.state.paypalBusiness)
  }

  refreshMedia(e) {
    e.preventDefault()
    this.props.onRefresh(this.state.media)
  }

  render() {
    const isProduct = this.state.media.link_type == 'product'
    const activeProduct = this.state.media.tagged_products.find((taggedProduct) => !taggedProduct._destroy)
    const canSell = this.props.identity.paypal_business && this.props.identity.paypal_business.length > 0
    const canSavePayPal = this.state.paypalBusiness && this.state.paypalBusiness.length > 0
    // const url = (this.state.media.image && this.state.media.image.standard_resolution.url) ? this.state.media.image.standard_resolution.url : this.state.media.image_standard_resolution_url
    const instagramUrl = this.props.media.image_thumbnail_url ? this.props.media.image_thumbnail_url : this.props.media.image_standard_resolution_url
    const url = (this.props.media.image && this.props.media.image.standard_resolution.url) ? this.props.media.image.standard_resolution.url : instagramUrl

    // const cachebusterUrl = `${url}?${Date.now()}`
    // console.log(`image_thumbnail_url=${this.props.media.image_thumbnail_url} image.standard_resolution.url=${this.props.media.image.standard_resolution.url}`)
    console.log(`url=${url}`)
    return(
      <div className="media-editor">
        <div className="media-editor__overlay" onClick={(e) => this.cancelEditing(e)}>
          <div className="media-editor__form" onClick={(e) => this.clickInside(e)}>
            <div className="media-editor__image">
              <img className="media-editor__real-image" src={url} alt="" />
              {!this.props.media.fetching &&
                <a href="#" className="media-editor__image-refresh-button" title="Refresh" onClick={(e) => this.refreshMedia(e)}>
                  <i className="fa fa-refresh" aria-hidden="true"></i>
                </a>
              }
              {this.props.media.fetching &&
                <div className="loader">Loading...</div>
              }
            </div>
            <div className="media-editor__details">
              <div className="media-editor__caption">{this.state.media.caption}</div>

              <div className="media-editor__toggle">
                <label className="radio">
                  <input 
                      type="radio" 
                      value="link" 
                      checked={!isProduct}
                      onChange={(e) => this.setLinkType(e)}
                      />
                  Link
                </label>
                <label className="radio">
                  <input 
                      type="radio" 
                      value="product" 
                      checked={isProduct}
                      onChange={(e) => this.setLinkType(e)}
                      />
                  Sell
                </label>
              </div>

              { // Is a link
                !isProduct &&
                <div className="form-group">
                  <label htmlFor="uri">URL</label>
                  <input 
                      type="text" 
                      id="uri" 
                      ref={(uriField) => this.uriField = uriField} 
                      placeholder="mybrand.com/awesomepage"
                      value={this.state.media.uri ? this.state.media.uri : ''} 
                      onChange={(e) => this.onUriChange(e)}
                      />
                    {/* onKeyUp={(e) => this.onUriKeyUp(e)} */}
                </div>
              }

              { // Need to setup paypal email
                isProduct && !canSell &&
                <div>
                  <div className="form-group media-editor__paypal">
                    <label id="email-label" htmlFor="product">Your PayPal business email</label>
                    <input aria-labelledby="email-label" type="email" placeholder="paypal@mycompany.com" value={this.state.paypalBusiness ? this.state.paypalBusiness : ''} onChange={(e) => this.onPayPalChange(e)}/>
                    <small className="form-text">Connect your PayPal business account and sell your products.</small>
                  </div>
                  <button 
                      type="button" 
                      className="btn btn--default" 
                      onClick={(e) => this.savePayPal()}
                      disabled={!canSavePayPal}
                      >Start selling</button>
                </div>
              }

              { // We have an assigned product
                isProduct && canSell && activeProduct &&
                <div>
                  <div className="media-editor__active-product-label">Product</div>
                  <div className="media-editor__active-product">
                    <div className="media-editor__active-product-name">{activeProduct.product.name} {currencyFormatter.format(activeProduct.product.amount / 100)}</div>
                    <a className="media-editor__remove-active-product" href="#" onClick={(e) => this.removeProduct(e)}>Remove</a>
                  </div>
                </div>
              }

              { // Select an existing product
                isProduct && canSell && !activeProduct && !this.state.newProduct &&
                <div className="form-group">
                  <label htmlFor="product">Product</label>
                  <ProductSelect products={this.props.products} onChange={(e) => this.onProductSelectChange(e)} />
                  <a href="#" onClick={(e) => this.addNewProduct(e)}>Add a new product</a>
                </div>
              }
              
              { // Create a new product
                isProduct && canSell && this.state.newProduct &&
                <div>
                  <ProductFields 
                      product={this.state.newProduct}
                      onNameChange={(e) => this.onProductNameChange(e)}
                      onAmountChange={(event, maskedvalue, floatvalue) => this.onProductAmountChange(event, maskedvalue, floatvalue)} />
                  <button type="button" className="btn media-editor__new-product-cancel" onClick={(e) => this.cancelNewProduct()}>Cancel</button>
                  <button 
                      type="button" 
                      className="btn btn--primary" 
                      onClick={(e) => this.saveNewProduct()}
                      disabled={!this.state.newProduct.amount || this.state.newProduct.amount < 0}
                      >Tag product</button>
                </div>
              }

              <hr/>

              <div className="media-editor__actions">
                <button type="button" className="btn btn--primary" onClick={(e) => this.saveMedia()}>Save link</button>
                <a href="#" className="media-editor__interior-close" onClick={(e) => this.cancelEditing(e)}>
                  <i className="fa fa-times" aria-hidden="true"></i>
                </a>
              </div>
            </div>
            <a href="#" className="media-editor__close" onClick={(e) => this.cancelEditing(e)}>
              <i className="fa fa-times" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>
    )
  }

  handleClickOutside(evt) {
    this.props.onCancel()
  }

}

export default onClickOutside(MediaEditor)

