import React, { PropTypes } from 'react'
import css from './SearchItem.scss'

export default class SearchItem extends React.Component {
  constructor(props, _railsContext) {
    super(props)
  }

  handleClick(e) {
    this.props.onClick(this.props.search)
  }

  render() {
    return(
      <article className="search-item" onClick={(e) => this.handleClick(e)}>
        {this.props.search.category == 'user' ? (
          <div className="search-item__container">
            <img src={this.props.search.profile_picture} className="search-item__profile-picture" alt="" />
            <div className="search-item__details">
              <div className="search-item__username">{this.props.search.name}</div>
              <div className="search-item__fullname">{this.props.search.full_name}</div>  
            </div>   
          </div>     
        ) : (
          <div className="search-item__container">
            <div className="search-item__hashtag">#</div>
            <div className="search-item__details">
              <div className="search-item__tagname">{this.props.search.name}</div>
            </div>
          </div>
        )}
      </article>
    )
  }

}