import React, { PropTypes } from 'react'
import ReactOnRails from 'react-on-rails'
import update from 'immutability-helper'
import FineUploaderTraditional from 'fine-uploader-wrappers'
import Dropzone from 'react-fine-uploader/dropzone'
import FileInput from 'react-fine-uploader/file-input'
import Thumbnail from 'react-fine-uploader/thumbnail'
import CancelButton from 'react-fine-uploader/cancel-button'
import request from 'axios'
import DatePicker from 'react-datepicker'
import moment from 'moment-timezone'
import onClickOutside from 'react-onclickoutside'
require('react-datepicker/dist/react-datepicker.css')
import TimePicker from '../../TimePicker/TimePicker.jsx'
import css from './Sharer.scss'

const uploader = new FineUploaderTraditional({
  options: {
    autoUpload: false,
    multiple: false,
    request: {
       endpoint: 'updates'
    }
  }
})

const isFileGone = status => {
  return [
    'canceled',
    'deleted'
  ].indexOf(status) >= 0
}

class Sharer extends React.Component {  
  constructor(props, _railsContext) {
    super(props)
    this.state = {
      update: props.update,
      submittedFiles: [],
      customTime: props.update.due_at !== undefined
    }
    this.statusChangeHandler = this.statusChangeHandler.bind(this)
    this.completeHandler = this.completeHandler.bind(this)
  }

  statusChangeHandler(id, oldStatus, newStatus) {
    if (newStatus === 'submitted') {
      const changedUpdate = update(this.state.update, {image: {$set: null}})
      const submittedFiles = this.state.submittedFiles
      for (var file of submittedFiles) {
        uploader.methods.cancel(file)
      }
      submittedFiles.push(id)
      this.setState({
        update: changedUpdate,
        submittedFiles: submittedFiles
      })
    } else if (isFileGone(newStatus)) {
      const submittedFiles = this.state.submittedFiles
      const indexToRemove = submittedFiles.indexOf(id)
      submittedFiles.splice(indexToRemove, 1)
      this.setState({ submittedFiles: submittedFiles })
    }
  }

  completeHandler(id, name, response) {
    this.props.onComplete(response)
  }

  componentDidMount() {
    uploader.on('statusChange', this.statusChangeHandler)
    uploader.on('complete', this.completeHandler)
    this.caption.focus()
  }

  componentWillUnmount() {
    uploader.off('statusChange', this.statusChangeHandler)
    uploader.off('complete', this.completeHandler)
  }

  handleCaptionChange(e) {
    const changedUpdate = update(this.state.update, {caption: {$set: e.target.value}})
    this.setState({
      update: changedUpdate
    })
    e.target.style.height = 'auto'
    e.target.style.height = e.target.scrollHeight + 'px'
  }

  saveUpdate(e) {
    // const uploads = uploader.getUploads()
    var file = undefined
    const submittedId = this.state.submittedFiles.shift()
    if (submittedId !== undefined) {
      file = uploader.methods.getFile(submittedId)
      // const input = uploader.methods.getInput(submittedId)
    }


    // uploader.methods.setParams({update: { caption: this.state.caption }})
    // uploader.methods.uploadStoredFiles()

    const requestConfig = {
      responseType: 'json',
      headers: ReactOnRails.authenticityHeaders()
    }

    if (this.state.update.id === undefined) {
      var update = this.state.update
      var data = new FormData()
      data.append('caption', update.caption)
      data.append('image', file)
      if (update.due_at) {
        data.append('due_at', update.due_at)
      }
      request
        .post('/app/updates.json', data, requestConfig)
        .then((response) => {
          this.props.onCreate(response.data)
        })
        .catch(error => {
          console.log('error: ' + error)
        })
    } else {
      var update = this.state.update
      var data = new FormData()
      data.append('caption', update.caption)
      if (file) {
        data.append('image', file) 
      }
      if (update.due_at) {
        data.append('due_at', update.due_at)
      }

      request
        .patch('/app/updates/' + this.state.update.id + '.json', data, requestConfig)
        .then((response) => {
          this.props.onUpdate(response.data)
        })
        .catch(error => {
          console.log('error: ' + error)
        })
    }
    


  }

  removeImage(e) {
    const changedUpdate = update(this.state.update, {image: {$set: null}})
    this.setState({
      update: changedUpdate
    })
  }

  handleDateChange(date) {
    var dueAt = moment(this.state.update.due_at)
    dueAt.set({'year': date.year(), 'month': date.month(), 'date': date.date()})

    const changedUpdate = update(this.state.update, {due_at: {$set: dueAt.toISOString()}})
    this.setState({
      update: changedUpdate
    })
  }

  handleTimeChange(time) {
    var dueAt = moment(this.state.update.due_at)
    const elements = time.split(':')
    const hour = parseInt(elements[0], 10)
    const minute = parseInt(elements[1], 10)
    dueAt.set({'hour': hour, 'minute': minute})

    const changedUpdate = update(this.state.update, {due_at: {$set: dueAt.toISOString()}})
    this.setState({
      update: changedUpdate
    })
  }

  cancelUpdate(e) {
    e.preventDefault()
    this.props.hide()
  }

  render() {
    const photoUploaded = this.state.submittedFiles.length > 0 || (this.state.update.image && this.state.update.image.url)
    const isValid = photoUploaded && this.state.update.caption.length > 0

    var insertButtonLabel = null;
    if (photoUploaded) {
      insertButtonLabel = <span>Replace photo</span>
    } else {
      insertButtonLabel = <span>Add a photo</span>
    }

    const newUpdate = this.props.update == null || this.props.update.id == null
    var saveLabel = 'Save'
    if (newUpdate) {
      saveLabel = 'Add to queue'
    }

    const avatarUrl = (this.props.identity.avatar && this.props.identity.avatar.url) ? this.props.identity.avatar.url : this.props.identity.profile_picture

    return(
      <div className="sharer">
        <div className="sharer__header">
          { avatarUrl &&
            <img className="sharer__profile-picture" src={avatarUrl} alt="" />
          }
          <a href="#" className="sharer__cancel" onClick={(e) => this.cancelUpdate(e)}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </a>
        </div>
        <div className="sharer__content">
          <div className={"sharer__composer" + (photoUploaded ? ' sharer__composer--photo' : '')}>
            <textarea ref={(caption) => this.caption = caption} 
                      placeholder="Write a caption..." 
                      className="sharer__caption" 
                      value={this.state.update.caption} 
                      onChange={(e) => this.handleCaptionChange(e)}
                      rows="1" />
          </div>
          <div className="sharer__attachment">
            {this.state.update.image &&
              <div className="sharer-thumbnail">
                <img src={this.state.update.image.url} className="sharer-thumbnail__image" alt="" />
                <div className="sharer-thumbnail__cancel" onClick={(e) => this.removeImage(e)}>
                  <i className="fa fa-times" aria-hidden="true"></i>
                </div>
              </div>
            }
            {this.state.submittedFiles.slice(0,1).map(id => (
              <div key={id} className="sharer-thumbnail">
                <Thumbnail className="sharer-thumbnail__image" id={id} uploader={uploader} maxSize={640} />
                <CancelButton className="sharer-thumbnail__cancel" id={id} uploader={uploader}>
                  <i className="fa fa-times" aria-hidden="true"></i>
                </CancelButton>
              </div>
            ))}
          </div>
        </div>
        {/*this.state.customTime &&
          <div className="sharer__time-picker">
            <div className="sharer__time-picker-heading">What time would you like to schedule your post?</div>
            <DatePicker
              selected={moment(this.state.update.due_at)}
              onChange={(e) => this.handleDateChange(e)}
              dateFormat="MMM D, YYYY"
              minDate={moment()} />
            <TimePicker time={moment(this.state.update.due_at).format('HH:mm')} onChange={(e) => this.handleTimeChange(e)}/>
          </div>
        */}
        <div className="sharer__actions">
          <div className="uploader">
            <Dropzone uploader={ uploader } className="uploader__dropzone" />
            <FileInput accept='image/*' uploader={ uploader } className="uploader__file-input" />
            <button type="button" className="uploader__insert-button">
              <i className="fa fa-camera" aria-hidden="true"></i>
              {insertButtonLabel}
            </button>
          </div>

          <button className="btn btn--primary sharer__share-button" 
                  onClick={(e) => this.saveUpdate(e)}
                  disabled={!isValid}>{saveLabel}</button>
          {/*<button>Share Next</button>
          <button>Share Now</button>
          <button>Schedule Post</button>*/}
        </div>
      </div>
    )
  }

  handleClickOutside(evt) {
    this.props.hide()
  }

}

export default onClickOutside(Sharer)