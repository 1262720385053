// import PropTypes from 'prop-types'
import React from 'react'
import css from './Toast.scss'

export default class Toast extends React.Component {  
  constructor(props) {
    super(props)
    this._isMounted = false
    this.state = {
      active: true
    }
  }

  componentDidMount() {
    this._isMounted = true
    setTimeout(function() {
      if (this._isMounted) this.setState({active: false})
    }.bind(this), 5000)
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  handleClick(e) {
    this.setState({
      active: false
    })
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    var toastClass = ''
    if (this.state.active) toastClass += 'toast__message--active'
    var message = null
    if (this.props.flash.notice) {
      toastClass += ' toast__message--alert'
      message = this.props.flash.notice
    } else if (this.props.flash.alert) {
      toastClass += ' toast__message--alert'
      message = this.props.flash.alert
    }
    return(
      <div className="toast">
        {message != null && 
          <div className={'toast__message ' + toastClass} onClick={(e) => this.handleClick(e)}>{message}</div>
        }
      </div>
    )
  }
}
