import React, { PropTypes } from 'react'
import css from './StatsNav.scss'
import onClickOutside from 'react-onclickoutside'

class StatsNav extends React.Component {  
  constructor(props, _railsContext) {
    super(props)
    this.state = {
      open: false
    }
  }

  toggleNav(e) {
    e.preventDefault()
    this.setState({
      open: !this.state.open
    })
  }

  onChange(e, identity) {
    e.preventDefault()
    this.props.onChange(identity)
    this.setState({
      open: false
    })
  }

  render() {
    var menuLabel = 'All accounts'
    if (this.props.identity != null) {
      menuLabel = this.props.identity.username
    }

    return(
      <div className="stats-nav">
        <a className="stats-nav__toggle" href="#" onClick={(e) => this.toggleNav(e)}>
          <span>{menuLabel}</span>
          <i className="fa fa-chevron-down" aria-hidden="true"></i>
        </a>
        {this.state.open && 
          <ul className="stats-nav__menu">
            <li><a className="stats-nav__link" href="#" onClick={(e) => this.onChange(e, null)}>All accounts</a></li>
            {this.props.identities.map((identity) =>
              <li key={identity.id}><a className="stats-nav__link" href="#" onClick={(e) => this.onChange(e, identity)}>{identity.username}</a></li>
            )}
          </ul>
        }
      </div>
    )
  }

  handleClickOutside(evt) {
    this.setState({
      open: false
    })
  }
}

export default onClickOutside(StatsNav)