import React, { PropTypes } from 'react'
import css from './UserNav.scss'
import onClickOutside from 'react-onclickoutside'
import request from 'axios'

class UserNav extends React.Component {  
  constructor(props, _railsContext) {
    super(props)
    this.state = {
      open: false
    }
  }

  toggleNav(e) {
    e.preventDefault()
    this.setState({
      open: !this.state.open
    })
  }

  render() {
    var planId = 'free'
    const sub = this.props.currentUser.subscription
    if (sub) {
      const plan = sub.plan
      if (plan) {
        planId = plan.stripe_id
      }
    }
    var multipleIdentities = this.props.currentUser.multiple_identities

    return(
      <div className="user-nav">
        <a className="user-nav__toggle" href="/user" onClick={(e) => this.toggleNav(e)}>
          {/*<span className="user-nav__toggle-email">{this.props.user.email}</span>*/}
          {/*<i className="fa fa-user" aria-hidden="true"></i>*/}
          <i className="fa fa-bars" aria-hidden="true"></i>
        </a>
        {this.state.open && 
          <div className="user-nav__popover">
            <ul className="user-nav__menu">
              <li className="user-nav__header">
                <img className="user-nav__gravatar" src={this.props.currentUser.gravatar} alt="" />
                <span>{this.props.currentUser.email}</span>
              </li>
              <li><a className="user-nav__link" href="/user/edit">Edit profile</a></li>
              <li><a className="user-nav__link" href="/user/password">Change your password</a></li>
              <li><a className="user-nav__link" href="/app/account/subscription">My account</a></li>

              {/*this.props.currentIdentity &&
                <li><a className="user-nav__link" href={'/app/identities/' + this.props.currentIdentity.id} rel="nofollow" data-method="delete">Disconnect {this.props.currentIdentity.username}</a></li>
              */}

              <li><a className="user-nav__link" href="/support">Support</a></li>

              {this.props.admin &&
                <li><a className="user-nav__link" href="/app/admin">Admin dashboard</a></li>
              }
              <li><a className="user-nav__link" href="/users/sign_out" rel="nofollow" data-method="delete">Sign out</a></li>
            </ul>
          </div>
        }
      </div>
    )
  }

  handleClickOutside(evt) {
    this.setState({
      open: false
    })
  }
}

export default onClickOutside(UserNav)