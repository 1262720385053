import React, { PropTypes } from 'react'
import css from './NotificationsNav.scss'
import onClickOutside from 'react-onclickoutside'
import request from 'axios'

class NotificationsNav extends React.Component {  
  constructor(props, _railsContext) {
    super(props)
    this.state = {
      open: false,
      notifications: []
    }
  }

  toggleNav(e) {
    e.preventDefault()
    this.setState({
      open: !this.state.open
    })
  }

  render() {
    return(
      <div className="notifications-nav">
        <a className="notifications-nav__toggle" href="/notifications" onClick={(e) => this.toggleNav(e)}>
          {this.props.notifications.length > 0 &&
            <i className="fa fa-bell notifications-nav__badge" aria-hidden="true"></i>
          }
          {this.props.notifications.length === 0 &&
            <i className="fa fa-bell-o" aria-hidden="true"></i>
          }
        </a>
        {this.state.open && 
          <div className="notifications-nav__popover">
            <ul className="notifications-nav__menu">
              <li className="notifications-nav__header">Notifications</li>
              {this.props.notifications.map((notification, index) => 
                <li key={index} className="notifications-nav__item">
                  <div className="notifications-nav__title">{notification.title}</div>
                  <div className="notifications-nav__text">{notification.text}</div>
                  <div className="notifications-nav__actions">
                    {notification.actions.map((action, index) =>
                      <a key={index} className="notifications-nav__link" href={action.link} target={action.blank ? '_blank' : '_self'}>{action.title}</a>
                    )}
                  </div>
                </li>
              )}
              {this.props.notifications.length === 0 &&
                <li className="notifications-nav__empty">You're all caught up.</li>
              }
            </ul>
          </div>
        }
      </div>
    )
  }

  handleClickOutside(evt) {
    this.setState({
      open: false
    })
  }
}

export default onClickOutside(NotificationsNav)