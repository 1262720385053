import React, { PropTypes } from 'react'
import css from './Burger.scss'
import onClickOutside from 'react-onclickoutside'

class Burger extends React.Component {  
  constructor(props, _railsContext) {
    super(props)
    this.state = {
      open: false
    }
    // this.defaultProps = {
    //   light: false,
    //   signed_in: false
    // }
  }

  static defaultProps = {
    light: false,
    signed_in: false
  }

  toggleNav(e) {
    e.preventDefault()
    this.setState({
      open: !this.state.open
    })
  }

  render() {
    let labelClass = this.props.light ? 'burger-menu__label burger-menu__label--light' : 'burger-menu__label'
    return(
      <div className="burger">
        <a className="burger-menu" onClick={(e) => this.toggleNav(e)}>
          <span className={labelClass}>Menu</span>
        </a>
        {this.state.open &&
          <div className="burger-popup">
            <div className="burger-popup__container">
              <a className="burger-popup__close" onClick={(e) => this.toggleNav(e)}>Close</a>
              <ul className="burger-popup__nav">
                <li className="burger-popup__item"><a href="/pricing" className="burger-popup__link">Pricing</a></li>
                <li className="burger-popup__item"><a href="/support" className="burger-popup__link">Support</a></li>
                <li className="burger-popup__item">
                  {this.props.signed_in ? (
                    <a href="/" className="burger-popup__link">Dashboard</a>
                  ) : (
                    <a href="/users/sign_in" className="burger-popup__link">Sign in</a>
                  )}
                </li>
              </ul>
            </div>
          </div>
        }
      </div>
    )
  }

  handleClickOutside(evt) {
    this.setState({
      open: false
    })
  }
}

export default onClickOutside(Burger)