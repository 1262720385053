import React, { PropTypes } from 'react';
import * as d3 from 'd3';
import 'd3-selection';
import 'd3-transition';

// class Chart extends React.Component {
export default class Chart extends React.Component {
  // constructor(props) {
  constructor(props, _railsContext) {
    super(props);
    this.margin = {
      top: 20,
      right: 20,
      bottom: 30,
      left: 40,
    };
    this.width = 960 - this.margin.left - this.margin.right;
    this.height = 500 - this.margin.top - this.margin.bottom;
    this.timeFormat = d3.timeFormat('%b %-d');
  }
  

  componentDidMount() {
    this.draw(Object.entries(this.props.data));
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateData(Object.entries(this.props.data));
  }

  draw(data) {
    // d3.select(this.graph).selectAll('*').remove();
    var _this = this;

    var barWidth = (this.width / data.length) * .9;

    var x = d3.scaleTime()
              .range([0, this.width]);

    var y = d3.scaleLinear()
              .range([this.height, 0]);

    var xAxis = d3.axisBottom(x)
                  .tickArguments([d3.timeWeek.every(1)])
                  .tickFormat(this.timeFormat);

    var yAxis = d3.axisLeft(y)
                  .ticks(4)
                  .tickSizeInner(-this.width)
                  .tickSizeOuter(0);

    var svg = d3.select(this.graph)
                .append('g')
                  .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

    x.domain([
      // new Date(data[0].key), 
      new Date(data[0][0]), 
      // d3.timeDay.offset(new Date(data[data.length - 1].key), 1)
      d3.timeDay.offset(new Date(data[data.length - 1][0]), 1)
    ]);

    y.domain([0, d3.max(data, function(d) {
      return d[1] > 0 ? d[1] : 10;
    })]);

    svg.append('g')
         .attr('class', 'x axis')
         .attr('transform', 'translate(0,' + this.height + ')')
         .call(xAxis);

    svg.append('g')
         .attr('class', 'y axis')
         .call(yAxis)
       // .append('text')
       //   .attr('transform', 'rotate(-90)')
       //   .attr('y', 6)
       //   .attr('dy', '.71em')
       //   .style('text-anchor', 'end');

    svg.selectAll('.bar')
       .data(data)
       .enter().append('rect')
         .attr('class', 'bar')
         .attr('x', function (d) {
          //  return x(new Date(d.key));
           return x(new Date(d[0]));
         })
         .attr('width', barWidth)
         .attr('y', function (d) {
          //  return y(d.value);
           return y(d[1]);
         })
         .attr('height', function (d) {
          //  return _this.height - y(d.value);
           return _this.height - y(d[1]);
         })
         .attr('data-tooltip', function (d) {
          //  return d.value + ' views on ' + _this.timeFormat(new Date(d.key));
           return d[1] + ' views on ' + _this.timeFormat(new Date(d[0]));
         });
  }

  updateData(data) {
    var _this = this;

    var x = d3.scaleTime()
              .range([0, this.width]);

    var y = d3.scaleLinear()
              .range([this.height, 0]);

    var xAxis = d3.axisBottom(x)
                  .tickArguments([d3.timeWeek.every(1)])
                  .tickFormat(this.timeFormat);

    var yAxis = d3.axisLeft(y)
                  .ticks(4)
                  .tickSizeInner(-this.width)
                  .tickSizeOuter(0);

    x.domain([
      // new Date(data[0].key), 
      new Date(data[0][0]), 
      // d3.timeDay.offset(new Date(data[data.length - 1].key), 1)
      d3.timeDay.offset(new Date(data[data.length - 1][0]), 1)
    ]);
    
    y.domain([0, d3.max(data, function(d) {
      return d[1] > 0 ? d[1] : 10;
    })]);

    var svg = d3.select(this.graph); //.data(data).transition();

    svg.select('.x.axis')
       .transition()
       .duration(750)
       .call(xAxis)

    svg.select('.y.axis')
       .transition()
       .duration(750)
       .call(yAxis)

    svg.selectAll('.bar')
       .data(data)
       .transition()
       .duration(750)
       .attr('y', function (d) {
        //  return y(d.value);
         return y(d[1]);
       })
       .attr('height', function(d) {
        //  return _this.height - y(d.value);
         return _this.height - y(d[1]);
       });

  }

  render() {
    return(
      <svg ref={(graph) => this.graph = graph} className="bargraph" viewBox="0 0 960 500" preserveAspectRatio="xMidYMid"></svg>
    );
  }
}

Chart.defaultProps = {
  data: {}
};