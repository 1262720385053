import React, { PropTypes } from 'react'
import update from 'immutability-helper'
import $ from 'jquery'
import Modal from 'react-modal'
import moment from 'moment-timezone'
import SentUpdate from './SentUpdate/SentUpdate.jsx'
import Update from './Update/Update.jsx'
import ClickOutsideSharer from './Sharer/Sharer.jsx'
import css from './UpdateQueue.scss'
import request from 'axios'

const requestConfig = {
  responseType: 'json',
  headers: ReactOnRails.authenticityHeaders()
}

Modal.setAppElement('body')

export default class UpdateQueue extends React.Component {
  constructor(props, _railsContext) {
    super(props)
    this.state = {
      updates: this.props.updates,
      editing: null,
      showSentUpdates: false,
      sentUpdates: [],
      updatesLeft: this.props.identity.updates_left
    }

    this.closeModal = this.closeModal.bind(this)
  }

  isLimited() {
    return this.props.identity.monthly_updates > -1
  }

  onCreate(response) {
    const newUpdates = update(this.state.updates, {
      $push: [response]
    })

    var updatesLeft = -1
    if (this.isLimited()) {
      updatesLeft = Math.max(this.state.updatesLeft - 1, 0)      
    }

    this.setState({
      updates: newUpdates,
      editing: null,
      updatesLeft: updatesLeft
    })
    this.closeEditor()
  }

  onUpdate(response) {
    const updateIndex = this.state.updates.findIndex(function(u) {
      return u.id === response.id;
    })
    const newUpdates = update(this.state.updates, {
      $splice: [[updateIndex, 1, response]]
    })
    this.setState({
      updates: newUpdates,
      editing: null
    })
    this.closeEditor()
  }

  newUpdate(e) {
    e.preventDefault();

    if (this.isLimited()) {
      if (this.state.updatesLeft == 0) {
        return
      }
    }

    this.setState({
      // status: 'composing'
      editing: { caption: '' }
    })
    this.openEditor()
  }

  closeModal() {
    this.setState({
      editing: null
    })
    this.closeEditor()
  }

  onEdit(post) {
    this.setState({
      editing: post
    })
    this.openEditor()
  }

  onDelete(post) {
    request
      .delete('/app/updates/' + post.id + '.json', requestConfig)
      .then((response) => {
        const index = this.state.updates.findIndex(function(u) {
          return u.id === post.id;
        })
        const updates = update(this.state.updates, {
          $splice: [[index, 1]]
        })
        var updatesLeft = -1
        if (this.isLimited()) {
          updatesLeft = Math.max(this.state.updatesLeft + 1, 0)
        }
        this.setState({
          updates: updates,
          updatesLeft: updatesLeft
        })
      })
      .catch(error => {
        console.log('delete error: ' + error)
      })
  }

  onShareNow(post) {
    request
      .put('/app/updates/' + post.id + '/share_now.json', requestConfig)
      .then((response) => {
        const index = this.state.updates.findIndex(function(u) {
          return u.id === post.id;
        })
        const updates = update(this.state.updates, {
          $splice: [[index, 1]]
        })
        const sentUpdates = update(this.state.sentUpdates, {
          $push: [response.data]
        })
        this.setState({
          updates: updates,
          sentUpdates: sentUpdates
        })
      })
      .catch(error => {
        console.log('share now error: ' + error)
      })
  }

  onSentDelete(post) {
    request
      .delete('/app/updates/' + post.id + '.json', requestConfig)
      .then((response) => {
        const index = this.state.sentUpdates.findIndex(function(u) {
          return u.id === post.id;
        })
        const sentUpdates = update(this.state.sentUpdates, {
          $splice: [[index, 1]]
        })
        this.setState({
          sentUpdates: sentUpdates
        })
      })
      .catch(error => {
        console.log('delete sent error: ' + error)
      })
  }

  toggleSentUpdates(e) {
    e.preventDefault()
    const showSentUpdates = !this.state.showSentUpdates
    this.setState({
      showSentUpdates: showSentUpdates
    })

    if (showSentUpdates) {
      this.fetchSentUpdates()
    }
  }

  fetchSentUpdates() {
    request
      .get('/app/schedule.json?sent=1', requestConfig)
      .then((response) => {
        this.setState({
          sentUpdates: response.data
        })
      })
      .catch(error => {
        console.log('fetch sent error: ' + error)
      })
  }

  openEditor() {
    var scrollTop = $(window).scrollTop();
    var $body = $('body');
    $body.css('position', 'fixed');
    $body.css('width', '100%');
    $body.css('top', -scrollTop);
  }

  closeEditor() {
    var $body = $('body');
    var scrollTop = $body.position().top;
    $body.css('position', '');
    $body.css('width', '');
    $body.css('top', '');
    $(window).scrollTop(-scrollTop);
  }

  render() {
    var groups = []
    for (var update of this.state.updates) {
      var date = moment(update.due_at).format('YYYY-MM-DD')
      if (!groups[date]) groups[date] = []
      groups[date].push(update)
    }

    var pastRemindersLabel = 'View past reminders'
    if (this.state.showSentUpdates) {
      pastRemindersLabel = 'Hide past reminders'
    }

    const url = (this.props.identity.avatar && this.props.identity.avatar.url) ? this.props.identity.avatar.url : this.props.identity.profile_picture

    return(
      <div className="update-queue">
        <div className={'update-queue__share-box' + ((this.state.updatesLeft == 0) ? ' update-queue__share-box--limited' : '')} onClick={(e) => this.newUpdate(e)}>
          {url &&
            <img className="update-queue__share-profile" src={url} alt="" />
          }
          <input aria-label="What would you like to share?" className="update-queue__share-text" type="text" placeholder="What would you like to share?" disabled />
        </div>

        {this.isLimited() &&
          <div className="update-queue__countdown">You have <strong>{this.state.updatesLeft} scheduled posts</strong> remaining this month. <a href="/app/account/subscription">Upgrade for more</a></div>
        }

        <div className="past-reminders">
          <div className="past-reminders__banner">
            <a href="/app/schedule" className="past-reminders__cta" onClick={(e) => this.toggleSentUpdates(e)}>
              <i className="fa fa-clock-o" aria-hidden="true"></i>
              {pastRemindersLabel}
            </a>
          </div>
          {this.state.showSentUpdates &&
            <div className="past-reminders__timeline">
              {this.state.sentUpdates.map((update) =>
                <SentUpdate key={update.id} 
                            identity={this.props.identity}
                            update={update}
                            onDelete={post => this.onSentDelete(post)}/>
              )}
            </div>
          }
          {this.state.showSentUpdates && this.state.sentUpdates.length == 0 &&
            <div className="update-queue__empty">
              <p>🤔 Nothing to see here. Why don’t you <a href="#" onClick={(e) => this.newUpdate(e)}>share a post</a>?</p>
            </div>
          }
        </div>

        <div>
          {Object.keys(groups).sort().map((key) =>
            <div key={key}>
              <h3 className="update-queue__heading">{moment(key).format('dddd, MMMM Do')}</h3>
              {groups[key].map((update) =>
                <Update key={update.id} 
                        update={update} 
                        identity={this.props.identity}
                        onEdit={post => this.onEdit(post)} 
                        onDelete={post => this.onDelete(post)}
                        onShareNow={post => this.onShareNow(post)}/>
              )}
            </div>
          )}
          {this.state.updates.length == 0 &&
            <div className="update-queue__empty">
              <p>👍 All clear! Now’s the perfect time to schedule an update.</p>
              <p><a className="btn btn--secondary" href="#" onClick={(e) => this.newUpdate(e)}>Share something</a></p>
            </div>
          }
        </div>

        <Modal
          isOpen={this.state.editing !== null}
          onRequestClose={this.closeModal}
          contentLabel="Update"
          className="update-queue__modal-content"
          overlayClassName="update-queue__modal-overlay"
        >
          <div className="update-queue__modal-sharer">
            <ClickOutsideSharer onCreate={response => this.onCreate(response)} 
                                onUpdate={response => this.onUpdate(response)} 
                                update={this.state.editing}
                                identity={this.props.identity}
                                hide={this.closeModal} />
          </div>
        </Modal>
      </div>
    )
  }

}
