import React, { PropTypes } from 'react';
import moment from 'moment-timezone';
import Chart from './Chart/Chart.jsx';
import css from './Stats.scss'
import ClickOutsideStatsNav from './StatsNav/StatsNav.jsx'
import request from 'axios'
import ReactOnRails from 'react-on-rails'
import Info from './Info/Info.jsx'
import StatsTableInfo from './Info/StatsTableInfo.jsx';

const mediaImageUrl = media => (
  (media.image && media.image.thumbnail.url) ? media.image.thumbnail.url : media.image_thumbnail_url
)

// class Stats extends React.Component {
export default class Stats extends React.Component {
  // constructor(props) {
  constructor(props, _railsContext) {
    super(props);
    this.state = {
      tab: 'views',
      identity: null,
      media: props.media,
      views: props.views,
      clicks: props.clicks,
      total_views: props.total_views,
      total_clicks: props.total_clicks,
      sortColumn: 'date',
      sortDirection: 'desc',
      endDate: moment(props.end_date),
      loading: true,
      loadingMedia: true,
      showInfo: false
    };
  }

  componentDidMount() {
    this.fetchStats(this.state.identity, this.state.endDate)
    this.fetchMedia(this.state.identity,this.state.endDate)
     // TODO: pass in endDate
  }

  changeIdentity(identity) {
    this.fetchStats(identity, this.state.endDate);
    this.fetchMedia(identity, this.state.endDate); // TODO: pass in endDate
  }

  previousMonth(e) {
    e.preventDefault();
    var previousEndDate = this.state.endDate.subtract(30, 'days');
    this.fetchStats(this.state.identity, previousEndDate);
    this.fetchMedia(this.state.identity, previousEndDate);
    // TODO: fetch media with endDate
  }

  nextMonth(e) {
    e.preventDefault();
    var nextEndDate = this.state.endDate.add(30, 'days');
    this.fetchStats(this.state.identity, nextEndDate);
    this.fetchMedia(this.state.identity, nextEndDate);
    // TODO: fetch media with endDate
  }

  fetchStats(identity, endDate) {
    this.setState({ identity: identity, endDate: endDate, loading: true });

    const requestConfig = {
      responseType: 'json',
      headers: ReactOnRails.authenticityHeaders()
    }

    request
      .get('/app/stats/load.json?end_date=' + endDate.format('YYYY-MM-DD') + '&identity=' + (identity ? identity.id : null) + '&stats_only=1', requestConfig)
      .then((response) => {
        this.setState({
          // media: response.data.media,
          views: response.data.views,
          clicks: response.data.clicks,
          total_views: response.data.total_views,
          total_clicks: response.data.total_clicks,
          loading: false
        });
      })
      .catch(error => {
        console.log('fetch stats error: ' + error)
      })

  }

  // TODO: pass in end date
  fetchMedia(identity, endDate) {
    // TODO: update state with new endDate and loadingMedia = true
    this.setState({identity: identity, endDate: endDate, loading: true});

    const requestConfig = {
      responseType: 'json',
      headers: ReactOnRails.authenticityHeaders()
    }

    request
      .get('/app/stats/media.json?identity=' + (identity ? identity.id : null) + '&end_date=' + (endDate.format('YYYY-MM-DD')), requestConfig)
      .then((response) => {
        this.setState({
          media: response.data,
          loadingMedia: false
        });
      })
      .catch(error => {
        console.log('fetch stats error: ' + error)
      })
  }


  changeTab(e, tab) {
    e.preventDefault();
    this.setState({ tab: tab });
  }

  sortTable(e, column) {
    var newDirection = 'desc';
    if (this.state.sortColumn == column) {
      if (this.state.sortDirection == 'desc') {
        newDirection = 'asc';
      }
    }
    this.setState({ sortColumn: column, sortDirection: newDirection });
  }

  sortMedia(a, b) {
    // determine direction
    var first = a;
    var second = b;
    if (this.state.sortDirection == 'desc') {
      first = b;
      second = a;
    }

    // compare column
    if (this.state.sortColumn == 'views')
      return (first.selected_views ?? 0) - (second.selected_views ?? 0);
    if (this.state.sortColumn == 'clicks')
      return (first.selected_clicks ?? 0) - (second.selected_clicks ?? 0);
    if (this.state.sortColumn == 'ctr')
      return first.selected_click_ratio - second.selected_click_ratio;
    return new Date(first.created_time).valueOf() - new Date(second.created_time).valueOf();
  }

  render() {
    let arrow = <i className="fa fa-arrow-up" aria-hidden="true"></i>
    if (this.state.sortDirection == 'asc') {
      arrow = <i className="fa fa-arrow-down" aria-hidden="true"></i>
    }
    let dateArrow = <i></i>;
    if (this.state.sortColumn == 'date') {
      dateArrow = arrow;
    }
    let viewsArrow = <i></i>;
    if (this.state.sortColumn == 'views') {
      viewsArrow = arrow;
    }
    let clicksArrow = <i></i>;
    if (this.state.sortColumn == 'clicks') {
      clicksArrow = arrow;
    }
    let ctrArrow = <i></i>;
    if (this.state.sortColumn == 'ctr') {
      ctrArrow = arrow;
    }

    return (
      <article className="page page-stats">

        {this.props.identities.length > 1 &&
          <ClickOutsideStatsNav 
            identities={this.props.identities}
            identity={this.state.identity}
            onChange={(identity) => this.changeIdentity(identity)} />
        }
        <ul className="stats-tabs">
          <li className={this.state.tab == 'views' ? 'active': ''}>
            <a href="#" onClick={(e) => this.changeTab(e, 'views')}>
              <div className="stats-total">{this.state.total_views}</div>
              <div className="stats-metric">Views (30 days)</div>
            </a>
          </li>
          <li className={this.state.tab == 'clicks' ? 'active': ''}>
            <a href="#" onClick={(e) => this.changeTab(e, 'clicks')}>
              
              <div className="stats-total">{this.state.total_clicks}</div>
              <div className="stats-metric">Clicks (30 days)</div>
            </a>
          </li>
          <Info/>
        </ul>
        
        <Chart data={this.state.tab == 'clicks' ? this.state.clicks : this.state.views} />

        <div className="stats-pagination">
          <a href="#" onClick={(e) => this.previousMonth(e)}><i className="fa fa-angle-left"></i> Prev 30 days</a>
          <span> | </span>
          {this.state.endDate.isBefore(moment().startOf('day')) ? (
            <a href="#" onClick={(e) => this.nextMonth(e)}>Next 30 days <i className="fa fa-angle-right"></i></a>
          ) : (
            <a href="#" className="disabled" onClick={(e) => e.preventDefault()}>Next 30 days <i className="fa fa-angle-right"></i></a>
          )}
        </div>
      
        <div className="stats-table">
          <table className="stats">
            <thead>
              <tr>
                <th onClick={(e) => this.sortTable(e, 'date')}>
                  <span>Date</span>
                  {dateArrow}
                </th>
                <th onClick={(e) => this.sortTable(e, 'views')}>
                  <span>Views</span>
                  {viewsArrow}
                </th>
                <th onClick={(e) => this.sortTable(e, 'clicks')}>
                  <span>Clicks</span>
                  {clicksArrow}
                </th>
                <th onClick={(e) => this.sortTable(e, 'ctr')}>
                  <span>CTR</span>
                  {ctrArrow}
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.media.sort((a, b) => this.sortMedia(a, b)).map((media) =>


                <tr className="stats-media" key={media.id}>
                  <td className="stats-media-title">
                    <div className="stats-media-photo">
                      <img src={mediaImageUrl(media)} alt="" />
                    </div>
                    <div className="stats-media-info">
                      <h2>{ media.caption }</h2>
                      <span className="stats-actions">
                        <a href={'/app/media/' + media.id + '/edit'}>View photo</a>
                      </span>
                    </div>
                  </td>
                  <td className="stats-media-item">
                    <span className="stats-value">{ media.selected_views ?? 0 }</span>
                    <span className="stats-label">Views</span>
                  </td>
                  <td className="stats-media-item">
                    <span className="stats-value">{ media.selected_clicks ?? 0 }</span>
                    <span className="stats-label">Clicks</span>
                  </td>
                  <td className="stats-media-item">
                    <span className="stats-value">{ media.selected_click_ratio }%</span>
                    <span className="stats-label">CTR</span>
                  </td>
                </tr>
              )}
              {this.state.media.length == 0 &&
                <tr><td colSpan="4" className="stats-empty">You don’t have any stats yet. Make sure to <a href="/app/link">add some linkable photos</a>.</td></tr>
              }
            </tbody>
          </table>
          <StatsTableInfo/>
        </div>

        {(this.state.loading || this.state.mediaLoading) &&
          <div className="stats__loading">Loading...</div>
        }
      </article>
    );
  } 
}

Stats.defaultProps = {
  identities: [],
  media: [],
  views: {},
  clicks: {},
  total_views: 0,
  total_clicks: 0,
  start_date: new Date(),
  end_date: new Date()
};
