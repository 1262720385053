import React, { PropTypes } from 'react';
import CurrencyInput from 'react-currency-input';
import css from './ProductFields.scss'

export default class ProductFields extends React.Component {
  constructor(props, _railsContext) {
    super(props)

    this.state = {
      name: this.props.product.name ? this.props.product.name : '',
      amount: this.props.product.amount ? this.props.product.amount : 0
    }
  }

  static defaultProps = {
    onNameChange: function() {},
    onAmountChange: function() {}
  }

  onNameChange(e) {
    const name = e.target.value
    this.setState({name: name})
    this.props.onNameChange(e)      
  }

  onAmountChange(event, maskedvalue, floatvalue) {
    const amount = floatvalue * 100
    this.setState({amount: amount})
    this.props.onAmountChange(event, maskedvalue, floatvalue)
  }

  render() {
    return(
      <div className="media-editor__new-product">
        <div className="form-group media-editor__new-product-name">
          <label htmlFor="product_name">Name</label>
          <input 
              type="text" 
              id="product_name" 
              name="product[name]"
              maxLength="127"
              placeholder="My Cool Product"
              value={this.state.name ? this.state.name : ''} 
              onChange={(e) => this.onNameChange(e)}
               />
        </div>
        <div className="form-group media-editor__new-product-amount">
          <label htmlFor="product_name">Price (USD)</label>
          <CurrencyInput 
              type="text" 
              id="product_amount" 
              placeholder="0.00"
              value={this.state.amount ? (this.state.amount / 100) : undefined}
              onChangeEvent={(e, m, f) => this.onAmountChange(e, m, f)}
               />
          <input type="hidden" name="product[amount]" value={this.state.amount} />
        </div>
      </div>      
    )
  }
}
